<template>
  <OnboardingStep
    :title="$t('onboarding.constantContactIntroView.title')"
    :next-text="$t('onboarding.constantContactIntroView.nextText')"
    v-bind="$props"
  >
    <I18nArray tag="p" path="onboarding.constantContactIntroView.paragraphs" />
    <RaiLink :to="{ name: 'onboarding.automations.constantContact.setup' }">
      {{ $t("onboarding.constantContactIntroView.connectConstantContactlink") }}
    </RaiLink>
  </OnboardingStep>
</template>

<script>
import { BaseStepView } from "./BaseStepView";
import I18nArray from "@/components/I18nArray";
import { RaiLink } from "@/core-ui";

export default {
  name: "ConstantContactIntroView",
  components: { I18nArray, RaiLink },
  extends: BaseStepView,
};
</script>
